

/* قاعدة لتنسيق شريط التنقل */
.navbar {
  background-color: #a2c7a2; /* اللون الأخضر الفاتح */
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px; /* إضافة زاوية دائرية للشريط */
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.navbar li {
  margin: 0 15px;
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 5px 10px; /* زيدة الحشوة لجعل الروابط أكبر */
  border-radius: 5px; /* إضافة زاوية دائرية للروابط */
}

.navbar a.active {
  background-color: #5d7a69; /* اللون الأخضر الداكن */
}

.navbar a:hover {
  background-color: #5c726e;
  border-radius: 5px;
}


/* Header styles */
header {
  background-color: #a2c7a2;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

header h1 {
  color: rgb(255, 255, 255);
  font-size: 2em;
  margin: 0;
}

/* Navigation bar */
nav {
  background-color: #a2c7a2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

nav ul li {
  margin: 0 10px;
}

nav ul li button {
  background: none;
  border: none;
  color: white;
  font-size: 1em;
  cursor: pointer;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
}
.url-input {
  width: 90%;
  padding: 8px;
  margin-bottom: 10px;
}

.url-description {
  font-size: 12px;
  color: #888;
  margin-bottom: 20px;
}
nav ul li button:hover,
nav ul li button.active {
  background-color: white;
  color: #a2c7a2;
  
  border-radius: 5px;
}

/* Current page highlighting */
.nav-link.active {
  background-color: #8fb08f;
  color: white;
  
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Ensure the body takes full width and height, and uses a responsive font size */
body {
  width: 100%;
  height: 100%;
  font-family: 'Tajawal', sans-serif;
  font-size: 14px;

  line-height: 1.5;
  direction: rtl;
  text-align: right;
}

/* Container adjustments */
.container {
  margin: 0 auto;
  
  padding: 20px;
}

/* Responsive typography */
h1 {
  font-family: 'Tajawal', sans-serif;
  font-size: calc(.5em + 1vw);
  text-align: center;
  background-color: #a2c7a2;
  color: white;
  padding: 20px;
}

h2 {
  font-family: 'Tajawal', sans-serif;
  font-size: calc(.4em + 1vw);
  text-align: center;
  color: #333;
}

/* Navigation bar styling */
nav {
  background-color: #a2c7a2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

nav ul li {
  margin: 10px;
}

nav ul li button {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1em;
  padding: 10px;
}

nav ul li button:hover, nav ul li .active-link {
  background-color: white;
  color: #a2c7a2;
  border-radius: 5px;
}

nav ul li .active-link {
  text-decoration: underline;
  
}

/* Responsive tables */
.table-container {
  word-break: break-word;

  overflow-x: auto;
}

table {
  width: 100%; 
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  cursor: pointer;
  color: #555;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #ddd;
}

@media (max-width: 600px) {
  th, td {
    word-break: break-word;

    display: block;
    width: 100%;
  }

  tr {
    display: block;
    margin-bottom: 10px;
  }

  thead {
    display: none;
  }
}

/* Form adjustments for mobile */
form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  width: 80%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

form h1, form select {
  color: #555;
  font-family: 'Tajawal', sans-serif;
}

form input, form select, form textarea {
  display: block;
  margin: 10px 0;
  padding: 10px;
  box-sizing: border-box;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #555;
  font-family: 'Tajawal', sans-serif;
}

form input[type="text"], 
form input[type="date"], 
form input[type="email"],
form input[type="password"],
form input[type="url"]
form select, 
form textarea,
form select {
  width: calc(90% - 10px);
}

form input:focus, form select:focus, form textarea:focus {
  outline: none;
  border-color: #a2c7a2;
  box-shadow: 0 0 5px rgba(162, 199, 162, 0.5);
}

form button {
  background-color: #a2c7a2;
  color: #555;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin: 0 auto;
  display: block;
  font-family: 'Tajawal', sans-serif;
  font-size: 1em;
}

form button:hover {
  background-color: #8fb08f;
}

form div {
  margin: 10px 0;
}

/* Special styling for search and reset buttons */
.search-container {
  
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.search-row {
  display: flex;
  gap: 10px;
}

.search-container input {
  padding: 10px;
  font-size: .75em;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Tajawal';
}

.search-container h2 {
  margin: 0;
  color: #555;
  font-weight: normal;
}

/* Checkbox styling */
.checkbox-container label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-container input[type="checkbox"] {
  margin-left: 10px;
}

/* Button styling */
button.edit-btn, button.delete-btn {
  background-color: #a2c7a2;
  color: #555;
  border: none;
  padding: 15px 30px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-family: 'Tajawal', sans-serif;
  font-size: 1.1em;
  margin: 5px; /* مسافة بسيطة بين الأزرار */
}

button.edit-btn:hover {
  background-color: #8fb08f;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

button.delete-btn {
  background-color: #c0392b; /* لون أحمر غامق */
  color: white;
}

button.delete-btn:hover {
  background-color: #e60000;
}

.modal button {
  background-color: #a2c7a2;
  color: #555;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-family: 'Tajawal', sans-serif;
  font-size: 1.1em;
}

.modal button:hover {
  background-color: #8fb08f;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Button for adding */
button.add-button {
  background-color: #a2c7a2;
  color: #555;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1.1em;
  border-radius: 4px;
}

button.add-button:hover {
  background-color: #8fb08f;
}

/* General button styling */
button {
  background-color: #a2c7a2;
  color: #555;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-family: 'Tajawal', sans-serif;
}

button:hover {
  background-color: #8fb08f;
}

button.edit-button {
  background-color: #a2c7a2;
}

button.edit-button:hover {
  background-color: #45a049;
}

button.reset-button {
  background-color: #f1c40f;
}

button.reset-button:hover {
  background-color: #d4ac0d;
}

button.small-delete-button {
  background-color: #e74c3c !important;
  color: white !important;
  border: none !important;
  padding: 5px 10px !important;
  cursor: pointer !important;
  font-size: 0.9em !important;
  border-radius: 5px !important;
  transition: background-color 0.3s, box-shadow 0.3s !important;
}

button.small-edit-button {
  background-color: #7d7c6e !important;
  color: white !important;
  border: none !important;
  padding: 5px 10px !important;
  cursor: pointer !important;
  font-size: 0.9em !important;
  border-radius: 5px !important;
  transition: background-color 0.3s, box-shadow 0.3s !important;
}
button.small-delete-button:hover {
  background-color: #ff0000 !important;
}

/* Adjust input fields to have uniform length */
.user-form input,
.user-form select,
.user-form textarea {
  width: calc(100% - 20px);
  margin: 10px 0;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  color: #555;
  box-sizing: border-box;
  font-family: 'Tajawal';
}

.search-input {
  display: block;
  margin: 5px 0;
  padding: 5px;
  font-size: .75em;
  border-radius: 5px;
  font-family: 'Tajawal';
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  
}


/* Styling add and edit buttons similarly */
.user-form button.add-button,
.user-form button.edit-button {
  background-color: #a2c7a2;
  color: #555;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  font-size: 1.1em;
  margin-right: auto;
}

.user-form button.add-button:hover,
.user-form button.edit-button:hover {
  background-color: #8fb08f;
}

.user-form .delete-button {
  background-color: #c0392b; /* لون أحمر غامق */
  color: white;
  border: none;
  padding: 15px 30px; /* تعديل حجم زر الحذف ليكون مساوياً لزر التعديل */
  cursor: pointer;
  border-radius: 4px;
  font-size: 1.1em; /* تعديل حجم خط زر الحذف ليكون مساوياً لزر التعديل */
}

.user-form .delete-button:hover {
  background-color: #e60000;
}
/* إضافة التنسيقات لضبط الجداول */
.settings-table {
  width: 50%;
  margin: 20px auto;
  border-collapse: collapse;
  table-layout: fixed;
}

.settings-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.settings-item {
  width: 80%;
  word-wrap: break-word;
}

.settings-action {
  width: 20%;
  word-wrap: break-word;
}

.settings-delete-button {
  width: 100%;
  padding: 8px 0;
}

@media (max-width: 600px) {
  .settings-table td {
    word-break: break-word;

    display: block;
    width: 100%;
  }

  .settings-table tr {
    display: block;
    margin-bottom: 10px;
  }

  .settings-table {
    border: 0;
  }

  .settings-item,
  .settings-action {
    width: 100%;
  }
}
/* Reset some elements for better cross-browser compatibility */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Ensure the body takes full width and height, and uses a responsive font size */
body {
  width: 100%;
  height: 100%;
  font-family: 'Tajawal', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  direction: rtl;
  text-align: right;
}

/* Container adjustments */
.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header {
  background-color: #a2c7a2;
  padding: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}



/* Responsive navigation */
nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 1em;
}

nav ul li {
  margin: 10px;
}

nav ul li button {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1em;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

nav ul li button:hover,
nav ul li button.active {
  background-color: #8fb08f;
  color: #000;
  
}

main {
  margin: 20px 0;
}

.small-edit-button, .small-delete-button {
  padding: 5px 10px;
  margin: 2px;
}

.small-edit-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.small-delete-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.small-edit-button:hover, .small-delete-button:hover {
  opacity: 0.8;
}

/* لإخفاء القائمة والتنقل قبل تسجيل الدخول */
.login-mode .navbar,
.login-mode .content {
  display: none;
}
/* لإخفاء القائمة والتنقل قبل تسجيل الدخول */
.login-mode .navbar,
.login-mode .content {
  display: none;
}

/* مركز نموذج تسجيل الدخول في منتصف الصفحة */
.login-container {
  position: fixed;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  width: 500px;
  text-align: center; /* محاذاة النص إلى المنتصف */
}

.login-container h2 {
  margin-bottom: 20px;
}

.login-container .error {
  color: #f44336;
  margin-bottom: 20px;
}

.login-container form label {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  text-align: right; /* محاذاة النص إلى اليسار */
}

.login-container form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.login-container form .password-input-container {
  position: relative;
}

.login-container form .show-password-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #a2c7a2;
}

.login-container form .show-password-button i {
  font-size: 1.2em;
}

.login-container form button {
  width: 100%;
  padding: 10px;
  background: #a8c5a8;
  border: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.login-container form button:hover {
  background: #a2bba2;
}

/* تنسيق نص "تذكرني" بجانب مربع الاختيار */
.login-container form .remember-me {
  display: flex;
  align-items: center;
  justify-content: center; /* محاذاة إلى المنتصف */
}

.login-container form .remember-me label {
  margin-left: 5px;
  margin-bottom: 0;
}

/* تنسيق نص "اظهار كلمة المرور" */
.login-container form .show-password-text {
  font-size: 0.8em; /* حجم الخط الصغير */
  cursor: pointer;
  color: #007bff;
  display: block;
  text-align: right;
  margin-top: -10px; /* إزالة الهامش العلوي */
}

/* تنسيق جديد لمرحبا وتسجيل الخروج */
.header-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content .welcome-message {
  position: absolute;
  right: 20px;
  top: 10px;
  color: white;
  
}

.header-content .logout-button {
  position: absolute;
  left: 20px;
  top: 10px;
  color: #f44336;
  
  cursor: pointer;
}


/* App.css */

.log-section {
  padding: 20px;
  text-align: center;
}

.log-filters {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.log-filters input {
  margin: 0 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.table-container {
  width: 80%;
  margin: 0 auto;
}

.log-table {
  width: 100%;
  border-collapse: collapse;
}

.log-table th,
.log-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}



.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 50%;
  height: 40%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.description-hover {
  color: blue;
  cursor: pointer;
  position: relative;
}

.description-hover .tooltip-text {
  visibility: hidden;
  width: 300px;
  background-color: #f9f9f9;
  color: #000;
  text-align: left;
  border: 1px solid #ddd;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 0;
}

.description-hover:hover .tooltip-text {
  visibility: visible;
}




button.small-more-button {
  color: #555;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1em;
  font-family: 'Tajawal', sans-serif;
}

.show-more-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 500px;
  width: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}